import { snapshotDistributionsByFarm } from '@api/Distributions'
import { ToolTips } from '@components'
import { Button, HeaderText, LoadingView, ToggleButton, Tooltip } from '@elements'
import { Distribution } from '@models/Distribution'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { DistributionRow } from './components/DistributionRow'

import { AdminView } from '@/admin/components/AdminView'
import InputLabel from '@/admin/components/InputLabel'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { DistributionSchedulesParamList } from '@/admin/navigation/types'
import Colors from '@/constants/Colors'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import useKeyedState from '@/hooks/useKeyedState'
import { useDeviceSize } from '@/hooks/useLayout'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { setAdminSchedules } from '@/redux/actions/adminPersist'
import { adminFarmIdSelector, adminSchedulesSelector } from '@/redux/selectors'
import { Permission } from '@helpers/Permission'
import { sortByName } from '@helpers/sorting'
import { isWeb } from '../../../constants/Layout'
import { globalStyles } from '../../../constants/Styles'
import { useSnapshot } from '../../../hooks/useApiFx'

function DistributionScreenComp() {
  const { isSmallDevice } = useDeviceSize()
  const navigation = useNavigation<StackNavigationProp<DistributionSchedulesParamList>>()
  const dispatch = useDispatch()
  const farmId = useSelector(adminFarmIdSelector)
  const distributions = useSelector(adminSchedulesSelector)
  const [{ showHidden }, set] = useKeyedState<{
    showHidden: boolean
  }>({ showHidden: false })
  const distrosShown = useMemo(
    () => distributions.filter((distro) => (showHidden ? true : !distro?.isHidden)),
    [distributions, showHidden],
  )

  /** Syncs the farm distros into admin redux */
  const { loading: loadingSchedules, error: loadErr } = useSnapshot(snapshotDistributionsByFarm, [farmId!], !!farmId, {
    onStateChange: ({ data }) => {
      // if data is undefined, do not set the schedules, as it will clear the existing schedules and cause the unnecessary re-render cycle and indirectly cause table not load all the data properly in the first render
      if (!data) return
      dispatch(setAdminSchedules(data?.sort(sortByName) ?? []))
    },
  })

  const styles = useStyles()

  const renderItem = useCallback<NonNullable<OfflineTableProps<Distribution>['renderItem']>>(
    ({ item: distro, index }) => {
      return !farmId ? null : <DistributionRow item={distro} index={index} key={distro.id} />
    },
    [farmId],
  )

  return (
    <AdminView style={styles.container}>
      <View style={styles.headerStyle}>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={30}>Schedules</HeaderText>
          <Tooltip style={styles.tooltip} title="Schedules" size={15} id={ToolTips.SCHEDULES} />
        </View>

        <View style={styles.headerRow}>
          <Button
            small
            style={styles.addScheduleButton}
            title="Add schedule"
            onPress={() => navigation.navigate('AddDistribution')}
          />
          <ToggleButton title="Show hidden" value={showHidden} onChange={() => set('showHidden', !showHidden)} />
          <InputLabel
            style={styles.toggleInputLabel}
            label=""
            tooltipId={ToolTips.TOGGLE_HIDDEN}
            tooltipTitle="Toggle Hidden Schedules"
          />
        </View>
      </View>
      <LoadingView loading={loadingSchedules || !farmId} error={loadErr} success={distrosShown}>
        {(data) => (
          <OfflineTable<Distribution>
            /** By default the scrollEnable is false, so the mobile app can scroll on the list without problem.
           * The reason to set scrollEnabled
           - 'isWeb' => For mobile web, we have to set scrollEnable true to make table scrollable and then trigger entire screen can be scrollable as well. And for normal big screen web, the behavior stays same.
           - '!isSmallDevice', for bigger touch screen like IPad, we have to make the table scrollable and since we set breakpoint as isSmallDevice, we set !isSmallDevice for all bigger touch screen to be able to scroll on app, for web version, it is covered by setting 'isWeb' */
            scrollEnabled={isWeb || !isSmallDevice}
            data={data}
            isLoading={!farmId || loadingSchedules}
            headerColumns={[
              { widthFlex: 0.4 /** Spacing for the color */ },
              { title: 'Schedule Name', widthFlex: 2 },
              { title: 'Location', widthFlex: 1.5 },
              { title: 'Type' },
              { title: 'Frequency' },
              { title: 'Dates', widthFlex: 2 },
              { title: 'Time & Day', widthFlex: 2 },
              { title: 'Status' },
              { title: 'Actions' },
            ]}
            renderItem={renderItem}
            containerStyle={styles.offlineTableContainer}
            minWidth={1200}
          />
        )}
      </LoadingView>
    </AdminView>
  )
}
export const DistributionScreen = withAdminAuth(DistributionScreenComp, Permission.ProductSetup)

const useStyles = () =>
  useLayoutFnStyles(({ isSmallDevice, height }) => {
    return {
      container: { marginHorizontal: isSmallDevice ? 10 : 30, marginTop: isSmallDevice ? 10 : 30 },
      headerStyle: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10,
      },
      headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      addScheduleButton: {
        marginRight: 20,
      },
      toggleInputLabel: {
        paddingBottom: 10,
      },
      row: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      tooltip: {
        marginLeft: 5,
        marginTop: 3,
      },

      viewButtonGroup: {
        borderRadius: 10,
        borderColor: Colors.semiTransparent,
        width: 90,
        marginHorizontal: 0,
        marginTop: 10,
      },
      viewButton: {
        borderColor: Colors.semiTransparent,
        padding: 10,
      },
      selectedButton: {
        backgroundColor: Colors.lightGray,
      },
      viewButtonIcon: {
        color: Colors.semiTransparent,
      },
      offlineTableContainer: {
        borderWidth: 1,
        /** If it is not smallDevice or not extraSmallDevice, it means scheduleList table is enabled to have nested scroll, so the maxHeight should be set to height * 0.77 (depend on the the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted (100%) to correct height of table to be able to show all rows from scrolling entire screen if need.
         * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
         */
        maxHeight: !isSmallDevice ? height * 0.77 : '100%',
      },
    }
  })
